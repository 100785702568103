import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  btn: {
    display: 'inline-block',
    cursor: 'pointer',
    border: `3px solid ${theme.palette.primary.main}`,
    background: theme.palette.primary.main,
    color: theme.palette.text.white,
    padding: '5px 20px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    boxShadow: `inset 0px 0px 0px 0px ${theme.palette.text.white}`,
    transition: 'box-shadow 0.2s ease-in-out',
    [theme.breakpoints.up('md')]: {
      padding: '15px 42px',
    },
    '&:hover': {
      boxShadow: `inset 0px 0px 0px 3px ${theme.palette.text.white}`,
    },
  },
  uppercase: {
    textTransform: 'uppercase',
  },
});

function ButtonBlockDark(props) {
  const { classes, link, icon, text } = props;
  return (
    <Link to={link} className={classes.btn}>
      <Box component="span" display="flex" alignItems="center">
        <Typography
          component="span"
          variant="button"
          className={classes.uppercase}
          align="center"
        >
          {text}
        </Typography>
        {icon && (
          <Box display="flex" alignItems="center" ml={2}>
            {icon}
          </Box>
        )}
      </Box>
    </Link>
  );
}

ButtonBlockDark.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string.isRequired,
  icon: PropTypes.object,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ButtonBlockDark);
