import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import linkResolver from 'utils/linkResolver';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { TextLink } from 'components/ui';

function Legal(props) {
  const { data } = props;
  const [date, setDate] = useState();
  const getYear = () => setDate(new Date().getFullYear());

  useEffect(() => {
    getYear();
  }, []);

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="button" color="secondary">
          Copyright &copy; Hayfield {date}. All rights reserved.
        </Typography>
      </Box>
      <Box
        component="ul"
        p={0}
        m={0}
        display="flex"
        justifyContent="space-between"
      >
        {data.map(({ title1, link }, key) => (
          <Box key={key} component="li" mb={1}>
            <TextLink
              title={title1[0].text}
              link={`${link ? linkResolver(link._meta) : '/'}`}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
}

Legal.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Legal;
