import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    body {
        overflow-x: hidden;
    }
    .menuOpen {
        overflow: hidden;
    }
    .carousel {
        position: relative;
        outline: 0;
    }
    .spaced-grid-wrapper {
        height: auto;
        overflow: hidden;
    }
    ul li {
      list-style: none;
    }
    img {
        max-width: 100%;
    }
    .menu--pinned {
      display: none;
    }
    .headroom-wrapper {
        position: absolute;
        width: 100%;
        z-index: 100;
    }
    .headroom {
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
      }
      .headroom--unfixed {
        position: relative;
        transform: translateY(0);
      }
      .headroom--scrolled {
        transition: transform 200ms ease-in-out;
      }
      .headroom--unpinned {
        position: fixed;
        transform: translateY(-100%);
      }
      .headroom--pinned {
        position: fixed;
        transform: translateY(0%);
      }
      .headroom--pinned,
      .headroom--unpinned {
        box-shadow: rgba(67, 134, 179, 0.15) 0px 0px 10px 0px;
      }
      .headroom--pinned .header, 
      .headroom--unpinned .header {
          background-color: #FFF;
          padding-bottom: 10px;
          padding-top: 10px;
      }
      .headroom--pinned .header .menu--block, 
      .headroom--unpinned .header .menu--block {
        display: none;
      }
      .headroom--pinned .header .menu--pinned, 
      .headroom--unpinned .header .menu--pinned {
        display: flex;
      }
`;
