import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Spacer from 'components/spacer';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';

import Awards from './Awards';
import Discover from './Discover';
import About from './About';
import CustomerService from './CustomerService';
import OutOfHours from './OutOfHours';
import Legal from './Legal';
import { Typography } from '@material-ui/core';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const FooterQuery = graphql`
  query {
    prismic {
      allLayouts {
        edges {
          node {
            title
            award_logo
            legal_links {
              title1
              link {
                ... on PRISMIC_Standard_page {
                  _meta {
                    uid
                    type
                    id
                  }
                }
                ... on PRISMIC_Home_type {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Contact_page {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Article {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Homepage {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Basic_page {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Development {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Search_page {
                  _meta {
                    id
                    type
                    uid
                  }
                }
              }
            }
            discover_menu {
              title1
              link {
                ... on PRISMIC_Homepage {
                  _linkType
                  _meta {
                    uid
                    type
                    id
                  }
                }
                ... on PRISMIC_Standard_page {
                  _meta {
                    uid
                    type
                    id
                  }
                }
                ... on PRISMIC_Home_type {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Contact_page {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Article {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Basic_page {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Development {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Search_page {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
            }
            about_menu {
              title1
              link {
                ... on PRISMIC_Homepage {
                  _meta {
                    uid
                    type
                    id
                  }
                }
                ... on PRISMIC_Standard_page {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Home_type {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Contact_page {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Article {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Basic_page {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Development {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
            }
            customer_service_block {
              title
              text
              telephone
              email
            }
            out_of_hours_emergency_block {
              telephone
              text
              title
            }
          }
        }
      }
    }
  }
`;

const styles = theme => ({
  footer: {
    backgroundColor: theme.palette.primary.main,
  },
  divider: {
    width: '1px',
    height: '100%',
    backgroundColor: theme.palette.text.white,
  },
  horzDivider: {
    height: '1px',
    backgroundColor: theme.palette.text.white,
  },
  light: {
    color: theme.palette.text.white,
    letterSpacing: '2.3px',
    lineHeight: '24px',
    textTransform: 'uppercase',
  },
  bold: {
    fontWeight: 600,
  },
  lg: {
    fontSize: '24px',
  },
  link: {
    textDecoration: 'none',
  },
  socialicon: {
    color: theme.palette.text.white,
    height: '30px',
    width: '30px',
    marginRight: '10px',
  },
  refLink: {
    letterSpacing: 'normal',
    textDecoration: 'none',
  },
});

function Footer(props) {
  const { classes } = props;
  return (
    <StaticQuery
      query={`${FooterQuery}`}
      render={data => (
        <Spacer>
          <Box component="footer" className={classes.footer} py={10}>
            <Container maxWidth={false}>
              <Grid container>
                <Grid item xs={12} lg={6}>
                  <Grid container>
                    <Grid item xs={12} sm={4}>
                      <Awards
                        data={data.prismic.allLayouts.edges[0].node.award_logo}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Discover
                        data={
                          data.prismic.allLayouts.edges[0].node.discover_menu
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <About
                        data={data.prismic.allLayouts.edges[0].node.about_menu}
                      />
                    </Grid>
                    <Box
                      component={Grid}
                      item
                      xs={6}
                      display={{ xs: 'none', lg: 'block' }}
                    >
                      <Box pt={4}>
                        <Legal
                          data={
                            data.prismic.allLayouts.edges[0].node.legal_links
                          }
                        />
                      </Box>
                    </Box>
                    <Grid item xs={2} />
                    <Box
                      component={Grid}
                      item
                      xs={4}
                      display={{ xs: 'none', lg: 'block' }}
                    >
                      <Box pt={4}>
                        <Typography
                          color="secondary"
                          component="p"
                          variant="subtitle1"
                        >
                          Follow us
                        </Typography>
                        <Box display="flex" flexDirection="row" mt={2}>
                          <Box>
                            <a
                              href="https://www.facebook.com/hayfieldhomes"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Box display="none" component="span">
                                Facebook
                              </Box>
                              <FacebookIcon className={classes.socialicon} />
                            </a>
                          </Box>
                          <Box>
                            <a
                              href="https://www.instagram.com/hayfieldhomes"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Box display="none" component="span">
                                Instagram
                              </Box>
                              <InstagramIcon className={classes.socialicon} />
                            </a>
                          </Box>
                          <Box>
                            <a
                              href="https://www.linkedin.com/company/hayfield-homes-official"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Box display="none" component="span">
                                LinkedIn
                              </Box>
                              <LinkedInIcon className={classes.socialicon} />
                            </a>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Box component={Grid} item sm={4} display={{ lg: 'none' }} />
                <Grid item xs={8} lg={6}>
                  <Grid container>
                    <Grid item sm={12} lg={6}>
                      <Grid item xs={12}>
                        <Box mb={4} mt={[4, 4, 4, 0]}>
                          <CustomerService
                            data={
                              data.prismic.allLayouts.edges[0].node
                                .customer_service_block
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <OutOfHours
                          data={
                            data.prismic.allLayouts.edges[0].node
                              .out_of_hours_emergency_block
                          }
                        />
                      </Grid>
                      <Box
                        component={Grid}
                        item
                        xs={12}
                        display={{ xs: 'block', lg: 'none' }}
                      >
                        <Box pt={4}>
                          <Typography
                            color="secondary"
                            component="p"
                            variant="subtitle1"
                          >
                            Follow us
                          </Typography>
                          <Box display="flex" flexDirection="row" mt={2}>
                            <Box>
                              <a
                                href="https://www.facebook.com/hayfieldhomes"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Box display="none" component="span">
                                  Facebook
                                </Box>
                                <FacebookIcon className={classes.socialicon} />
                              </a>
                            </Box>
                            <Box>
                              <a
                                href="https://www.instagram.com/hayfieldhomes"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Box display="none" component="span">
                                  Instagram
                                </Box>
                                <InstagramIcon className={classes.socialicon} />
                              </a>
                            </Box>
                            <Box>
                              <a
                                href="https://www.linkedin.com/company/hayfield-homes-official"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Box display="none" component="span">
                                  LinkedIn
                                </Box>
                                <LinkedInIcon className={classes.socialicon} />
                              </a>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Box
                      component={Grid}
                      item
                      xs={2}
                      display={{ xs: 'none', lg: 'block' }}
                    >
                      <Box className={classes.divider} mx="auto" />
                    </Box>
                    <Box
                      component={Grid}
                      item
                      xs={4}
                      display={{ xs: 'none', lg: 'block' }}
                    >
                      <Box>
                        <a
                          href="https://realestatecapitalholdings.com/"
                          target="_blank"
                          rel="noreferrer"
                          className={classes.link}
                        >
                          <Typography
                            component="span"
                            variant="subtitle1"
                            className={`${classes.light} ${classes.bold} ${classes.lg}`}
                          >
                            Real
                            <br />
                            Estate
                          </Typography>
                        </a>
                      </Box>
                      <Box mb={6}>
                        <a
                          href="https://realestatecapitalholdings.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classes.link}
                        >
                          <Typography
                            component="span"
                            variant="subtitle1"
                            className={`${classes.light}`}
                          >
                            Capital
                            <br />
                            Holdings
                          </Typography>
                        </a>
                      </Box>
                      <Box mb={2}>
                        <a
                          href="http://www.terrastrategic.co.uk"
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classes.link}
                        >
                          <Typography
                            component="span"
                            variant="subtitle1"
                            className={`${classes.light} ${classes.bold} ${classes.lg}`}
                          >
                            Terra
                          </Typography>
                        </a>
                      </Box>
                      <Box mb={2}>
                        <a
                          href="http://www.livingspacehousing.co.uk"
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classes.link}
                        >
                          <Typography
                            component="span"
                            variant="subtitle1"
                            className={`${classes.light} ${classes.bold} ${classes.lg}`}
                          >
                            Living Space
                          </Typography>
                        </a>
                      </Box>
                      <Box mb={2}>
                        <a
                          href="https://www.merrion-holdings.com/"
                          target="_blank"
                          rel="noreferrer"
                          className={classes.link}
                        >
                          <Typography
                            component="span"
                            variant="subtitle1"
                            className={`${classes.light} ${classes.bold} ${classes.lg}`}
                          >
                            Merrion
                          </Typography>
                        </a>
                      </Box>
                      <Box mb={2}>
                        <a
                          href="https://www.gateway-land.co.uk/"
                          target="_blank"
                          rel="noreferrer"
                          className={classes.link}
                        >
                          <Typography
                            component="span"
                            variant="subtitle1"
                            className={`${classes.light} ${classes.bold} ${classes.lg}`}
                          >
                            Gateway
                          </Typography>
                        </a>
                      </Box>
                      <Box>
                        <a
                          href="https://www.verdanthomes.co.uk/"
                          target="_blank"
                          rel="noreferrer"
                          className={classes.link}
                        >
                          <Typography
                            component="span"
                            variant="subtitle1"
                            className={`${classes.light} ${classes.bold} ${classes.lg}`}
                          >
                            Verdant
                          </Typography>
                        </a>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Box
                  component={Grid}
                  item
                  xs={12}
                  display={{ xs: 'block', lg: 'none' }}
                >
                  <Grid container>
                    <Box
                      component={Grid}
                      item
                      xs={12}
                      my={4}
                      className={classes.horzDivider}
                      display="block"
                    />
                    <Box component={Grid} item xs={12} sm={4} mb={4}>
                      <Legal
                        data={data.prismic.allLayouts.edges[0].node.legal_links}
                      />
                    </Box>
                    <Box
                      component={Grid}
                      item
                      xs={2}
                      display={{ xs: 'none', sm: 'block' }}
                    />
                    <Grid item xs={6} sm={3}>
                      <Box>
                        <a
                          href="https://realestatecapitalholdings.com/"
                          target="_blank"
                          rel="noreferrer"
                          className={classes.link}
                        >
                          <Typography
                            component="span"
                            variant="subtitle1"
                            className={`${classes.light} ${classes.bold} ${classes.lg}`}
                          >
                            Real
                            <br />
                            Estate
                          </Typography>
                        </a>
                      </Box>
                      <Box>
                        <a
                          href="/"
                          target="_blank"
                          rel="noreferrer"
                          className={classes.link}
                        >
                          <Typography
                            component="span"
                            variant="subtitle1"
                            className={`${classes.light}`}
                          >
                            Capital
                            <br />
                            Holdings
                          </Typography>
                        </a>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Box mb={2}>
                        <a
                          href="http://www.terrastrategic.co.uk"
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classes.link}
                        >
                          <Typography
                            component="span"
                            variant="subtitle1"
                            className={`${classes.light} ${classes.bold} ${classes.lg}`}
                          >
                            Terra
                          </Typography>
                        </a>
                      </Box>
                      <Box mb={2}>
                        <a
                          href="http://www.livingspacehousing.co.uk"
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classes.link}
                        >
                          <Typography
                            component="span"
                            variant="subtitle1"
                            className={`${classes.light} ${classes.bold} ${classes.lg}`}
                          >
                            Living Space
                          </Typography>
                        </a>
                      </Box>
                      <Box mb={2}>
                        <a
                          href="https://www.merrion-holdings.com/"
                          target="_blank"
                          rel="noreferrer"
                          className={classes.link}
                        >
                          <Typography
                            component="span"
                            variant="subtitle1"
                            className={`${classes.light} ${classes.bold} ${classes.lg}`}
                          >
                            Merrion
                          </Typography>
                        </a>
                      </Box>
                      <Box mb={2}>
                        <a
                          href="https://www.gateway-land.co.uk/"
                          target="_blank"
                          rel="noreferrer"
                          className={classes.link}
                        >
                          <Typography
                            component="span"
                            variant="subtitle1"
                            className={`${classes.light} ${classes.bold} ${classes.lg}`}
                          >
                            Gateway
                          </Typography>
                        </a>
                      </Box>
                      <Box>
                        <a
                          href="https://www.verdanthomes.co.uk/"
                          target="_blank"
                          rel="noreferrer"
                          className={classes.link}
                        >
                          <Typography
                            component="span"
                            variant="subtitle1"
                            className={`${classes.light} ${classes.bold} ${classes.lg}`}
                          >
                            Verdant
                          </Typography>
                        </a>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                mt={8}
              >
                <Typography
                  component="a"
                  variant="subtitle2"
                  href="https://www.inetdigital.co.uk"
                  color="secondary"
                  className={classes.refLink}
                >
                  Website by iNet Digital
                </Typography>
              </Box>
            </Container>
          </Box>
        </Spacer>
      )}
    />
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
