import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  link: {
    textDecoration: 'none',
    transition: 'all 0.3s ease-in-out',
    textDecorationColor: `${theme.palette.text.white} !important`,
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.text.light,
    },
  },
});

function TextLinkExternal(props) {
  const { title, link, classes } = props;
  return (
    <Typography
      component="a"
      href={link}
      className={classes.link}
      variant="button"
      color="secondary"
    >
      {title}
    </Typography>
  );
}

TextLinkExternal.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TextLinkExternal);
