import React from 'react';
import PropTypes from 'prop-types';
import linkResolver from 'utils/linkResolver';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { TextLink, TextLinkExternal } from 'components/ui';

function About(props) {
  const { data } = props;
  return (
    <Box>
      <Box mb={2}>
        <Typography variant="subtitle1" color="secondary">
          ABOUT
        </Typography>
      </Box>
      <Box component="ul" p={0} m={0}>
        {data.map(({ title1, link }, key) => (
          <Box key={key} component="li" mb={1}>
            {link.__typename != 'PRISMIC__ExternalLink' && (
              <TextLink
                title={title1[0].text}
                link={`${link ? linkResolver(link._meta) : '/'}`}
              />
            )}
            {link.__typename == 'PRISMIC__ExternalLink' && (
              <TextLinkExternal title={title1[0].text} link={`${link.url}`} />
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

About.propTypes = {
  data: PropTypes.array.isRequired,
};

export default About;
