import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  tagWrapper: {
    border: `1px solid ${theme.palette.background.border}`,
    borderRadius: '20px',
    display: 'inline-block',
    background: 'transparent',
    transition: 'all 0.3s ease-in-out',
  },
  tag: {
    color: theme.palette.background.border,
    textTransform: 'uppercase',
  },
});

function Tag(props) {
  const { data, classes } = props;
  return (
    <Box className={`${classes.tagWrapper} tag`} py={1} px={2} mx={1} my={1}>
      <Typography component="p" variant="button" className={classes.tag}>
        {data}
      </Typography>
    </Box>
  );
}

Tag.propTypes = {
  data: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Tag);
