import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { Context } from 'store/provider';
import Helmet from 'react-helmet';
import Head from 'components/head';
import Header from 'components/header';
import Nav from 'components/header/nav';
import GroupNav from 'components/header/nav/groupnav';
import Footer from 'components/footer';
import GlobalStyle from 'global.css.js';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from 'styled-components';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from 'styles/theme';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { withStyles } from '@material-ui/styles';
import Headroom from 'react-headroom';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import Link from 'gatsby-link';
import { Typography } from '@material-ui/core';

const styles = theme => ({
  blur: {
    transition: 'all 0.1s ease-in-out',
    '&.active': {
      filter: 'blur(5px)',
      transform: 'scale(0.99)',
    },
  },
  overlay: {
    position: 'absolute',
    zIndex: -1,
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    background: 'rgba(255, 255, 255, 0.6)',
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
    '&.active': {
      opacity: 1,
      zIndex: 1,
    },
  },
  topLink: {
    position: 'fixed',
    bottom: '-30px',
    right: '30px',
    zIndex: 10,
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    border: 0,
    backgroundColor: theme.palette.text.lblue,
    transition: 'all 0.3s ease-in-out',
    cursor: 'pointer',
    outline: 0,
    opacity: 0,
    '&.show': {
      opacity: 1,
      bottom: '30px',
    },
    '&:hover': {
      boxShadow: '1px 1px 20px -6px rgba(0,0,0,0.75)',
    },
  },
  icon: {
    color: 'white',
  },
});

function Layout(props) {
  const {
    themeColor,
    developmentPageMenu,
    homeTypePageMenu,
    classes,
    data,
    children,
  } = props;
  const [state] = useContext(Context);

  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (typeof window !== 'undefined') {
      if (!showScroll && window.pageYOffset > 400) {
        setShowScroll(true);
      } else {
        setShowScroll(false);
      }
    }
  };

  useEffect(() => {
    typeof window !== 'undefined' &&
      window.addEventListener('scroll', checkScrollTop);
    return () => {
      // remove resize listener
      typeof window !== 'undefined' &&
        window.addEventListener('scroll', checkScrollTop);
    };
  }, []);

  return (
    <div id="app">
      <Helmet>
        <body
          className={`${
            state.menu || state.body_fixed || state.groupmenu
              ? 'menuOpen'
              : 'menuClosed'
          }`}
        />
      </Helmet>
      <GlobalStyle />
      <Head />
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Nav themeColor={themeColor} />
          <div
            className={`${classes.blur} ${
              state.menu || state.groupmenu ? 'active' : 'inactive'
            }`}
          >
            <div
              className={`${classes.overlay} ${
                state.menu ? 'active' : 'inactive'
              }`}
            />
            <Headroom>
              <Header
                title={data.site.siteMetadata.siteTitle}
                themeColor={themeColor}
                developmentPageMenu={developmentPageMenu}
                homeTypePageMenu={homeTypePageMenu}
              />
            </Headroom>
            {children}
            <Footer />
            <button
              aria-label="Scroll to top"
              className={`${classes.topLink} ${showScroll ? 'show' : 'hide'}`}
              onClick={() => scrollTo('#app')}
            >
              <ExpandLessIcon className={classes.icon} />
            </button>
          </div>
          <GroupNav themeColor={themeColor} />
        </ThemeProvider>
      </MuiThemeProvider>
      <CookieConsent
        location="bottom"
        declineButtonText="Decline"
        buttonText="Accept"
        buttonWrapperClasses="cookie-btns"
        cookieName="HayFieldHomes"
        style={{ background: '#1f1b37' }}
        buttonStyle={{
          color: 'rgb(31, 27, 55)',
          fontSize: '13px',
          backgroundColor: '#fff',
        }}
        declineButtonStyle={{
          color: 'rgb(31, 27, 55)',
          fontSize: '13px',
          backgroundColor: '#fff',
        }}
        expires={150}
        enableDeclineButton
      >
        <Typography color="secondary" component="span" variant="caption">
          This website uses cookies to enhance the user experience.{' '}
          <Typography
            component={Link}
            to="/cookie-policy"
            color="secondary"
            variant="caption"
          >
            Click here to find out more
          </Typography>
        </Typography>
      </CookieConsent>
    </div>
  );
}

Layout.propTypes = {
  themeColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
  developmentPageMenu: PropTypes.object || PropTypes.string,
  homeTypePageMenu: PropTypes.object,
  classes: PropTypes.object.isRequired,
};

const myStaticQuery = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        siteTitle
      }
    }
  }
`;

const LayoutWithQuery = props => (
  <StaticQuery
    query={`${myStaticQuery}`}
    render={data => <Layout data={data} {...props} />}
  />
);

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(LayoutWithQuery);
